<template>
  <div>
    <b-row>
      <b-col>
        <h2>Change Case Status</h2>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col>
        <b-form-group
          label="Lookup Case by Case Number"
          label-for="caseNumber"
        >
          <b-form-input
            id="caseNumber"
            v-model="CaseNumber"
            placeholder=""
            name="caseNumber"
          />
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 1.5rem;">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="reset"
          variant="outline-secondary"
          :disabled="!CaseNumber"
          @click="lookup()"
        >
          Lookup
        </b-button>
      </b-col>
    </b-row>
    <div v-if="isNotClosed">
      <b-row>
        <b-col>
          <span style="color: indianred">Case is not closed</span>
        </b-col>
      </b-row>

    </div>
    <div v-if="editable">
      <b-row>
        <b-col>
          <span>Case: {{ caseObj.FileNumber }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <span>Press Reopen Case to mark this case as open.</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
            id="change-state-btn"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="submit"
            variant="primary"
            :disabled="afterClick"
            @click="changeStatus()"
          >
            Reopen Case
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {BButton, BCol, BFormGroup, BFormInput, BRow} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import APIService from "@core/utils/APIService";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const apiService = new APIService();

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      caseObj: {},
      CaseNumber: "",

      isNotClosed: false,
      editable: false,
      afterClick: false,
    }
  },
  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          })
    },
    changeStatus() {
      this.afterClick = true;
      apiService
          .post("case/" + this.caseObj.CaseID + "/unclose")
          .then(res => {
            if (res) {
              this.afterClick = false;
              this.showToast('success', 'top-center', 4000, 'Case ' + this.CaseNumber + '’s status has changed to open.');
            }
          })
    },
    lookup() {
      apiService
          .get("case/get/byfilenumber/" + this.CaseNumber)
          .then((response) => {
            if (response.data.CaseID) {
              this.caseObj = response.data;
              if (this.caseObj.DateClosed) {
                this.editable = true;
              } else {
                this.isNotClosed = true;
              }
            } else {
              this.showToast('danger', 'top-center', 4000, 'Case filenumber not found!');
            }
          })
    },
  }
}
</script>

<style scoped>

</style>